import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  font-weight: normal !important;
  background-color: #f6f3f3;
  width: 100%;
`

const Subtitle = styled.p`
  margin-top: 8px;
  font-size: 14px;
`

const Undertext = styled.li`
  font-size: 16px;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`

const MapBanner = styled.img`
  width: 350px;
  margin-bottom: 10px;
  @media (max-width: 600px) {
    width: 240px;
  }
`

const NewBanner = styled.img`
  position: absolute;
  width: 71px;
  left: 33%;
  top: 28%;
  @media (max-width: 600px) {
    left: 31%;
  }
  @media (max-width: 500px) {
    left: 28%;
  }
  @media (max-width: 450px) {
    left: 25%;
  }
  @media (max-width: 400px) {
    left: 20%;
  }
`

const SubHeaderWrapper = styled.ul`
  list-style-type: none;
  font-family: ${(props) => props.theme.font.primary};
  padding: 50px;
  text-align: center;
  margin-bottom: 0px;
  padding-bottom: 12px;
  @media (max-width: 600px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media (max-width: 400px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`

const Registerbutton = styled.button`
  margin-top: 8px;
  cursor: pointer;
  width: 40%;
  background-color: white;
  border: unset;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  border-radius: 12px;
  padding: 10px;
  font: normal normal normal 20px 'Fira Sans';
  letter-spacing: 0px;
  color: #f50c1a;

  &:hover {
    color: black;
  }

  @media (max-width: 800px) {
    width: 95%;
    padding: 8px;
  }
`

const Undertext_p = styled.p`
  margin-left: 25px;
  margin-right: 25px;

  @media (max-width: 900px) {
    margin-left: unset;
    margin-right: unset;
  }
`

const Registerfield = styled.div`
  font-family: ${(props) => props.theme.font.primary};
  text-align: center;
  background-color: #f50c1a;
  color: white;
  padding: 20px;
`

const Pone = styled.div`
  font-family: ${(props) => props.theme.font.primary};
  font-size: 19px;
  @media (max-width: 600px) {
    font-size: 17px;
  }
`

const CommunityNewsBanner = ({ ...props }) => {
  return (
    <Wrapper {...props}>
      <SubHeaderWrapper>
        <li>
          <Pone>
            <b>Das Gemeindeblatt aus Lana - jetzt auf STOL lesen!</b>
          </Pone>
          <Subtitle>
            Ein Klick auf den Button, registrieren und du bist mittendrin - natürlich <b>kostenlos!</b>
          </Subtitle>
        </li>
        <li>
          <NewBanner src={'https://s3-italy.brennercom.it/stol-images/img/speciale/piano/gemeindenews/neu.png'} />

          <MapBanner src={'https://s3-italy.brennercom.it/stol-images/img/speciale/gemeindenews/st_map.svg'} />
        </li>
        <Undertext>
          <p>
            <b>Du bist nicht aus Lana?</b> <br />
            In Zukunft wirst du natürlich nur dir News aus der Gemeinde angezeigt bekommen, für die du dich
            interessierst. Bis es soweit ist, kannst du dir mit dem Gemeindeblatt Lana schon ein Bild machen, wie es
            aussehen wird.
          </p>
        </Undertext>
        <Undertext>
          <Undertext_p>
            <b>
              Meld dich zu unserem Newsletter an, und erfahre als Erster, wenn auch dein Gemeindeblatt online verfügbar
              ist.
            </b>
          </Undertext_p>
        </Undertext>
      </SubHeaderWrapper>
      <Registerfield>
        <Registerbutton>Kostenlos registrieren!</Registerbutton>
      </Registerfield>
    </Wrapper>
  )
}
CommunityNewsBanner.propTypes = {}
CommunityNewsBanner.defaultProps = {}
export default CommunityNewsBanner
