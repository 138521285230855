import styled from 'styled-components'

const PlusDialog = styled.div.attrs(() => ({
  id: 'plusDialog',
}))`
  clear: both;
  margin-bottom: ${(props) => props.theme.mobile.sizes.gapVertical};
  scroll-margin: 50px;
  margin-left: -40px;
  margin-right: -40px;

  @media (min-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    top: calc(${(props) => props.theme.mobile.sizes.gapVertical} + 250px);
  }

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-left: -20px;
    margin-right: -20px;
  }
`

export default PlusDialog
