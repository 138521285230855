import Encryption from '../../../../../../../../modules/encryption'
import React from 'react'
import PlusDialog from '../PlusDialog'
import TextSkeleton from '../../../../../../../../components/Text/views/TextSkeleton'
import ContentDefault from '../ContentDefault'
import { useUserContext } from '../../../../../../../../components/ContextUser'
import consoleLogger from '../../../../../../../../modules/console-logger'
import styled from 'styled-components'
import DescriptionDetailArticle from '../../../DescriptionDetailArticle'

const PlusTeaser = styled(DescriptionDetailArticle)`
  margin-bottom: ${(props) => props.theme.mobile.sizes.gapVertical};
`

const ContentSplus = ({ plusContent, plusTeaser, grantAccess = false, contentOptions }) => {
  const [consoleLog] = consoleLogger('ContentDetailArticle')
  const {
    access: { isPlus },
  } = useUserContext()
  consoleLog('plus', isPlus, 'UseAccess')

  if (isPlus === undefined) {
    return (
      <>
        <TextSkeleton lines={5} fontSize="16px" lastWidth="45px" justify={false} />
        <TextSkeleton lines={3} fontSize="16px" lastWidth="65px" justify={false} />
      </>
    )
  }

  if (isPlus === false && !grantAccess) {
    return (
      <>
        <PlusTeaser text={plusTeaser} inverted={contentOptions?.inverted} fontSize={contentOptions?.fontSize} />
        <PlusDialog id="plusDialog" />
      </>
    )
  }
  const encryption = new Encryption()
  const html = encryption.decrypt(plusContent, process.env.RAZZLE_SPLUS_CONTENT_KEY)
  return <ContentDefault {...contentOptions} html={html} />
}

export default ContentSplus
