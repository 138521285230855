import React from 'react'
import styled from 'styled-components'

const CommunityDialog = styled.div.attrs(() => ({
  id: 'communityDialogLogin',
}))`
  clear: both;
  scroll-margin: 50px;

  @media (min-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    top: calc(${(props) => props.theme.mobile.sizes.gapVertical} + 250px);
  }
`

export default CommunityDialog
