import { createGlobalStyle } from 'styled-components'

const GlobalBlurStyle = createGlobalStyle`
  #articleContent.blur {
    filter: blur(10px);
    pointer-events: none;
  }
`

export default GlobalBlurStyle
