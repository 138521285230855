import styled from 'styled-components'
import imageHelpDeskTeam from './images/fa_helpdesk_team.png'

const Wrapper = styled.div`
  font-family: ${(props) => props.theme.font.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 26px;
  background-color: #f6f3f3;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding: 15px 12px 15px 15px;
    color: #000000;
  }
`
const ImageHelpDeskTeam = styled.img`
  margin-bottom: 12px;

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: 200px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: 70%;
  }
`
const TextHeadLine = styled.div`
  color: #707070;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    color: #000000;
  }
`
const TextHeadLine1 = styled(TextHeadLine)`
  font-weight: bold;
  font-size: 26px;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: 14px;
    font-weight: 700;
  }
`
const TextHeadLine2 = styled(TextHeadLine)`
  font-size: 22px;
  font-weight: 500;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    text-align: center;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: 12px;
    font-weight: 400;
    width: 70%;
  }
`
const WrapperPhoneNumber = styled.div`
  display: flex;
  font-size: 42px;
  font-weight: bold;
  margin-top: 7px;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: 10px;
    flex-direction: column;
    align-items: center;
  }
`
const TextPhoneNumber = styled.div`
  color: #707070;
  margin-right: 10px;
  font-weight: 500;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: 23px;
    margin-right: unset;
  }
`
const PhoneNumber = styled.a`
  color: #1a172a;
  text-decoration: none;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: 27px;
  }
`

const BannerUserHelp = () => (
  <Wrapper>
    <ImageHelpDeskTeam src={imageHelpDeskTeam} />
    <TextHeadLine1>Online unmeldn isch zi kompliziert?</TextHeadLine1>
    <TextHeadLine2>Ka Sorge! Anfoch zin Telefon greifn und ins unleitn.</TextHeadLine2>
    <WrapperPhoneNumber>
      <TextPhoneNumber>Mir helfen dir:</TextPhoneNumber>
      <PhoneNumber href="tel:+390471052660">+ 39 0471 052 660</PhoneNumber>
    </WrapperPhoneNumber>
  </Wrapper>
)

export default BannerUserHelp
