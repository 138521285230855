import ContentDefault from '../ContentDefault'
import React from 'react'
import { useUserContext } from '../../../../../../../../components/ContextUser'
import CommunityDialog from '../CommunityDialog'
import isStolApp from '../../../../../../../../modules/is-stolapp'
import CommunityNewsBanner from '../../../CommunityNewsBanner'
import BannerUserHelp from '../../../CommunityNewsBanner/components/BannerUserHelp'

const ContentCommunityNews = ({ html, contentOptions }) => {
  const { profile } = useUserContext()

  const addRegisterScreen = () => {
    if (isStolApp()) {
      window.location.href = 'https://www.stol.it/anmelden'
    } else {
      tp.push([
        'init',
        function () {
          tp.pianoId.show({
            disableSignUp: false,
            displayMode: 'inline',
            containerSelector: '#communityDialogLogin',
            screen: 'login',
            showCloseButton: false,
            registrationSuccess: function (data) {
              let data_register_event = {
                event: 'register',
                register_url: 'https://www.stol.it/spezial/gemeindeblatt',
              }
              window.dataLayer.push(data_register_event)
            },

            loggedIn: function (data) {
              tp.experience.execute()
            },
            loggedOut: function () {},
            close: function () {},
          })
        },
      ])
    }
  }

  if (!profile) {
    return (
      <>
        <CommunityDialog onClick={() => addRegisterScreen()}>
          <CommunityNewsBanner />
        </CommunityDialog>
        <BannerUserHelp />
      </>
    )
  }

  return <ContentDefault {...contentOptions} html={html} />
}

export default ContentCommunityNews
